module.exports = function($) {
    $(document).ready(function() {
        // Search bar show/hide.
        $('.toggle-search').click(function(e) {
            //e.preventDefault();
            e.stopPropagation()
            var el = $('.searchForm');
            el.toggleClass('toggled');

            if(el.hasClass('toggled')) {
                document.getElementById('keyword').focus();
            }
        });

       $('.hide-search').click(function(e) {
            e.preventDefault();

            $('.searchForm').removeClass('toggled');
        });

        $(document).on("click", "#view-results", function(e) {
            e.preventDefault();

            var href = $(this).attr('data-href'),
            queryString = 's='+encodeURIComponent($('#keyword').val());
            newHref;
            newHref = href + '?' + queryString;

           window.location.href = newHref;
        })

        $("input#keyword").keyup(function() {
            if ($(this).val().length > 2) {
                $("#datafetch").show();
                fetch();
            } else {
                $("#datafetch").hide();
            }
        });

        /*
            ==================
            Ajax Search
            ======================	 
        */
        // add the ajax fetch js

        function fetch(){

            $.ajax({
                url: '/wp-admin/admin-ajax.php',
                type: 'post',
                data: { action: 'data_fetch', keyword: $('#keyword').val() },
                success: function(data) {
                    data = JSON.parse(data);
                    if(data.length > 0) {
                        /* if(data.length > 7) {
                            $("#view-results").show();
                        } else {
                             $("#view-results").hide();
                        } */
                        var html = '<ul>';
                        data.slice(0, 7).forEach(element => {
                            html += '<li>';
                                html += '<a href="'+element['url']+'" class="search-result-item">';
                                    html += '<span>'+element['title']+'</span>';
                                    html += '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">';
                                        html += '<path stroke-linecap="round" stroke-linejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />';
                                    html += '</svg>';
                                html += '</a>';
                            html += '</li>';
                        });
                        html += '</ul>';
                    } else {
                        var html = '<ul>';
                            html += '<li>No Data Found!</li>';
                        html += '</ul>';
                        // $("#view-results").hide();
                    }
                    
                    $('#datafetch').html( html );
                }
            });

        }

         // Search bar show/hide.
        /* $(window).click(function() {
            var el = $('.searchForm');
            //Hide the menus if visible
            //el.toggleClass('toggled');
            if(el.hasClass('toggled')) {
                el.toggleClass('toggled');
            }
        }); */

    });
}
