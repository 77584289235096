module.exports = function($) {
    $(document).ready(function() {
        // Elements selected with selector.
        var els = document.querySelectorAll('.sidebar, .page-content .two-column .tc-container-right');
        // Height of the viewport window.
        var winheight = window.innerHeight;

        for (var i=0; i<els.length; i++) {
            // This element position relative to the viewport.
            var rect = els[i].getBoundingClientRect();
            // Height of the rendered element.
            var elheight = els[i].offsetHeight;

            if(elheight <= winheight) {
                els[i].classList.add('sticky');
            }
        }
    });
}
