
import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider";
module.exports = function($) {
    $(document).ready(function() {
        /* $("#tabs-tabVertical .nav-link").on( "click", function() {
            $("#tabs-tabVertical .nav-link").removeClass("active");
            $(this).addClass("active");

            var activeTab =  $(this).attr("data-bs-target").replace(/#/, '');

            $('#tabs-tabContentVertical .tab-pane').animate({opacity: 0}, 10, function() {
                $('#'+activeTab).animate({opacity: 1}, 500).addClass("show active");
            }).removeClass('show active');
        

           /*  $(".tab-pane").removeClass("show active");
            $('#'+activeTab).addClass("show active"); *
        }) */

        document.querySelectorAll('.tabImageSlider').forEach(slider => {
            tns({
                container: slider,
                items: 1,
                //autoplay: true,
                autoplayText: "",
                items: 1,
                controls: true,
                swipeAngle: true,
                speed: 400,
                nav: true,
                navPosition: "bottom",
                slideBy: "page",
                loop: false,
                autoplay: false,
                controlsText: ["<", ">"]
                
            });
        });
       /*  var slider1 = tns({
            container: '.tabImageSlider',
            items: 1,
            //autoplay: true,
            autoplayText: "",
            items: 1,
            controls: false,
            swipeAngle: true,
            speed: 400,
            nav: true,
            navPosition: "bottom",
            slideBy: "page",
            loop: false,
            autoplay: false,
        }); */

        let timerOpacity;
        // Menu Click
        $('.tabs .nav-tabs .nav-link').off( 'click' ).on( 'click', function( e ) {
			// Prevent Default
			e.preventDefault();
			e.stopPropagation();

			// Clear Timers
			window.clearTimeout( timerOpacity );

			// Toggle Class Logic
			// Remove Active Classes

            var activeTab =$(this).attr('data-bs-target');
            var parentContent = $(this).attr("data-parent");
            
            var $tabs = $(this).closest('.nav-tabs');
           
            $tabs.find('a.active').removeClass('active');
            console.log($(this).hasClass('nav-link-inner'));
            if(!$(this).hasClass('nav-link-inner'))
               $(activeTab).find('.nav-link').removeClass('active')
            
            if($(this).hasClass('nav-link-inner'))
                $('#'+parentContent).find('.tab-pane-inner.active.show').removeClass('active show');
            else
                $('#'+parentContent).find('.tab-pane.active.show').removeClass('active show');


			// Add Active Classes
            $(this).addClass('active');
            $('[data-bs-target="'+activeTab+'"]').addClass('active');
            $(activeTab).addClass( 'active' );


            $('#tab-option').val(activeTab.replace('#', ''))

			// Opacity Transition Class
			timerOpacity = setTimeout( () => {
				$(activeTab).addClass( 'show' );
			}, 50 );
		} );

        $('.restaurant-menu  .nav-tabs .nav-link').off( 'click' ).on( 'click', function( e ) {
			// Prevent Default
			e.preventDefault();
			e.stopPropagation();

			// Clear Timers
			window.clearTimeout( timerOpacity );

			// Toggle Class Logic
			// Remove Active Classes

            var activeTab =$(this).attr('data-bs-target');
            var parentContent = $(this).attr("data-parent");
            
            var $tabs = $(this).closest('.nav-tabs');
           
            $tabs.find('a.active').removeClass('active');
            
            
            if($(this).hasClass('nav-link-inner'))
                $('#'+parentContent).find('.tab-pane-inner.active.show').removeClass('active show');
            else
                $('#'+parentContent).find('.tab-pane.active.show').removeClass('active show');


			// Add Active Classes
            $(this).addClass('active');
            $('[data-bs-target="'+activeTab+'"]').addClass('active');
            $(activeTab).addClass( 'active' );


            $('#tab-option').val(activeTab.replace('#', ''))

			// Opacity Transition Class
			timerOpacity = setTimeout( () => {
				$(activeTab).addClass( 'show' );
			}, 50 );
		} );
        

        $('.tab-option').on("change", function(e) {
            
           
            var target = e.target.value;
            var parentUL = $('[data-bs-target='+target).parent().attr('id'); 
            var parentTarget = $('#'+target).parent().attr('id');

            // Prevent Default
			e.preventDefault();
			e.stopPropagation();

			// Clear Timers
			window.clearTimeout( timerOpacity );

			// Toggle Class Logic
			// Remove Active Classes
            $('#'+parentUL +' .nav-link').removeClass('active')
            $('#'+parentTarget +' .tab-pane').removeClass('active');
            $('#'+parentTarget +' .tab-pane').removeClass('show');

			// Add Active Classes
            $('[data-bs-target='+target).addClass('active')
            $('#'+target).addClass('active');

			// Opacity Transition Class
			timerOpacity = setTimeout( () => {
				$('#'+target).addClass( 'show' );
			}, 50 );
        })
   
    });
}
