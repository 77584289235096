/**
 * Script to lazy load all CSS background-images
 * Element must have a class of .lazy-background
 * 
 * Usage: Import this moduile into the main JS file and call the lazyLoading function
 * import lazyLoading from './components/lazy-loading';
 * lazyLoading();
 */
const lazyLoading = () => {

    const lazyImages = [...document.querySelectorAll('.lazy-background')];

    if ("IntersectionObserver" in window) {
        
        let lazyImageObserver = new IntersectionObserver( (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const lazyImage = entry.target;
                    // lazyImage.src = lazyImage.dataset.src;
                    console.log( lazyImage );
                    lazyImage.classList.remove('lazy-background');
                    lazyImageObserver.unobserve(lazyImage);
                }
            });
        });

        lazyImages.forEach((lazyImage) => {
            lazyImageObserver.observe(lazyImage);
        });
    }
}

export default lazyLoading;