module.exports = function($) {
    $(document).ready(function() {
        $('.accordion .item h3').click(function(e) {
            var clicked = $(this).parent();

            if(clicked.hasClass('toggled')) {
                clicked.removeClass('toggled');
            } else {
                $('.accordion .item.toggled').removeClass('toggled');
                clicked.addClass('toggled');
            }
        });
    });
}
