import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider"
module.exports = function($) {
    
    $(document).ready(function() {

        if($('#banner-slider').length > 0) {

            var slider = tns({
                container: '#banner-slider',
                items: 1,
                //autoplay: true,
                autoplayText: "",
                items: 1,
                controls: false,
                swipeAngle: true,
                speed: 400,
                nav: true,
                navPosition: "bottom",
                slideBy: "page",
                loop: false,
                autoplay: false,
                controls: false,
                controlsText: ["<", ">"],
                responsive: {
                    '320': {
                        "items": 1,
                        nav: false,
                        mouseDrag: true,
                        touch: true,
                        controls: true,
                        controlsText: ["<", ">"]
                    },
                    '767': {
                        nav: true,
                        controls: false,
                        controlsText: ["<", ">"]
                    }
                }
            });
        }

        if($('.pbICimageMobileSlide').length > 0) {
            var slider = tns({
                "container": ".pbICimageMobileSlide",
                "autoplayText": "",
                "items": 1,
                "controls": true,
                "swipeAngle": true,
                "speed": 400,
                "nav": true,
                "navPosition": "bottom",
                "slideBy": "page",
                "loop": false,
                "autoplay": false,
                controlsText: ["<", ">"]
                
            });
        }

        $('.banner-inner-slider-left').each(function() {
            var sliderone = tns({
                "container": this, // Use the current element as the container
                "autoplayText": "",
                "items": 1,
                "controls": false,
                "swipeAngle": true,
                "speed": 400,
                "nav": true,
                "navPosition": "bottom",
                "slideBy": "page",
                "loop": false,
                "autoplay": false,
            });
        });

        if($('.banner-head-slider').length > 0) {
            var slider = tns({
                "container": ".banner-head-slider",
                "autoplayText": "",
                "items": 1,
                "controls": false,
                "swipeAngle": true,
                "speed": 400,
                "nav": true,
                "navPosition": "bottom",
                "slideBy": "page",
                "loop": false,
                "autoplay": false,
                
            });
        }

        $('.banner-inner-slider-right').each(function() {
            var sliderone = tns({
                "container": this, // Use the current element as the container
                "autoplayText": "",
                "items": 1,
                "controls": false,
                "swipeAngle": true,
                "speed": 400,
                "nav": true,
                "navPosition": "bottom",
                "slideBy": "page",
                "loop": false,
                "autoplay": false,
            });
        });

        if($('.testimonials-list ').length > 0) {
            var slider = tns({
                "container": ".testimonials-list ",
                "autoplayText": "",
                "items": 1,
                "controls": false,
                "swipeAngle": true,
                "speed": 400,
                "nav": true,
                "navPosition": "bottom",
                "slideBy": "page",
                "loop": false,
                "autoplay": false,
                
            });
        }


        if($('#menu-item').length > 0) {

            var slider = tns({
                container: '#menu-item',
                items: 1,
                //autoplay: true,
                autoplayText: "",
                items: 1,
                controls: false,
                swipeAngle: true,
                speed: 400,
                nav: true,
                navPosition: "bottom",
                slideBy: "page",
                loop: false,
                autoplay: false,
                controls: false,
                controlsText: ["<", ">"],
                responsive: {
                    '320': {
                        "items": 1,
                        nav: false,
                        mouseDrag: true,
                        touch: true,
                        controls: true,
                        controlsText: ["<", ">"]
                    },
                    '767': {
                        nav: true,
                        controls: false,
                        controlsText: ["<", ">"]
                    }
                }
            });
        }

        if($('#menu-item-loop').length > 0) {

            var slider = tns({
                container: '#menu-item-loop',
                items: 1,
                //autoplay: true,
                autoplayText: "",
                items: 1,
                controls: false,
                swipeAngle: true,
                speed: 400,
                nav: true,
                navPosition: "bottom",
                slideBy: "page",
                loop: false,
                autoplay: false,
                controls: false,
                controlsText: ["<", ">"],
                responsive: {
                    '320': {
                        "items": 1,
                        nav: false,
                        mouseDrag: true,
                        touch: true,
                        controls: true,
                        controlsText: ["<", ">"]
                    },
                    '767': {
                        nav: true,
                        controls: false,
                        controlsText: ["<", ">"]
                    }
                }
            });
        }

        // if(typeof bannerAnimation !== 'undefined') {
        //     $('.banner.owl-carousel').owlCarousel({
        //         animateIn: (bannerAnimation == 'default' ? '' : bannerAnimation + 'In'),
        //         animateOut: (bannerAnimation == 'default' ? '' : bannerAnimation + 'Out'),
        //         items: 1,
        //         dots: false,
        //         nav: true,
        //         navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
        //         autoHeight: true,
        //         onInitialized: function(e) {
        //             if (!e.namespace) {
        //                 return;
        //             }
        //
        //             // Hide controls if there is only one slide.
        //             if(e.item.count === 1) {
        //                 $(e.target).addClass('no-controls');
        //             }
        //
        //             // Remove loading class.
        //             $(e.target).removeClass('banner-loading');
        //
        //             var slideNumber = (parseInt(e.item.index) + 1);
        //
        //             // Add a span element to display the slide number.
        //             $('<span>' + (slideNumber < 10 ? '0' + slideNumber : slideNumber) +'</span>').insertAfter('.banner.owl-carousel .owl-nav > .owl-prev');
        //         },
        //         onChanged: function(e) {
        //             if (!e.namespace) {
        //                 return;
        //             }
        //
        //             var slideNumber = (parseInt(e.item.index) + 1);
        //
        //             // Update the value of the displayed slide number.
        //             $('.banner.owl-carousel .owl-nav > span').text((slideNumber < 10 ? '0' + slideNumber : slideNumber));
        //         }
        //     })
        // }

    })
}
