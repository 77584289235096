module.exports = function($) {
    $(document).ready(function() {
        // $('.logo-carousel .logos').owlCarousel({
        //     loop: true,
        //     center: true,
        //     autoplay: true,
        //     responsive: {
        //         0: {
        //             items: 2,
        //             margin: 30,
        //             autoWidth: true,
        //         },
        //         600: {
        //             items: 3,
        //             margin: 50
        //         },
        //         992: {
        //             items: 4,
        //             margin: 100
        //         }
        //     }
        // });
    });
}
