// Third party.
require('./partials/modernizr.js');
var $ = window.jQuery; // = require('jquery');
require('tiny-slider');
//require('./partials/jquery-ui.js');
// require('@fortawesome/fontawesome-free/js/all.js');
var CountUp = require('countup.js');

// Default functionality for navigation/search etc.
var navigation = require('./sections/navigation.js')($, Modernizr);
var search = require('./sections/search.js')($);

// Default functionality for each section required on the website.
var banner = require('./sections/banner.js')($);
//var twocolumn = require('./sections/two-column.js')($, Modernizr);
//var countup = require('./sections/count-up.js')($, CountUp, Modernizr);
var logocarousel = require('./sections/logo-carousel.js')($);
var stickyelements = require('./sections/sticky-elements.js')($);
var accordion = require('./sections/accordion.js')($);
var tabs = require('./sections/tabs.js')($);

require("./sections/mobile-menu.js");
require("./partials/lightbox.js");
import lazyLoading from './partials/lazy-loading';
import AOS from 'aos';

$(document).ready(function() {
  var page = 2;
    lazyLoading();
    /**
     * Custom functionality and overrides.
     */

    // HTML markup implementation, overlap mode
    AOS.init({
        once: true,   // whether animation should happen only once - while scrolling down
        // Global settings:
       /*  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
        startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
        initClassName: 'aos-init', // class applied after initialization
        animatedClassName: 'aos-animate', // class applied on animation
        useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
        disableMutationObserver: false, // disables automatic mutations' detections (advanced)
        debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
        throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
        
      
        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        offset: 120, // offset (in px) from the original trigger point
        delay: 0, // values from 0 to 3000, with step 50ms
        duration: 400, // values from 0 to 3000, with step 50ms
        easing: 'ease', // default easing for AOS animations
        once: true, // whether animation should happen only once - while scrolling down
        mirror: false, // whether elements should animate out while scrolling past them
        anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation */
      });
	
      $('body').on('click', '.load-more-event', function() {
        let postCount = $(this).attr('data-post-count');
        var data = {
            'action': 'load_posts_by_ajax',
            'page': page,
            'security': 'nonce'
        };
  
        $.post('/wp-admin/admin-ajax.php', data, function(response) {
            if($.trim(response) != '') {
                $('.upcoming-event-list').append(response);
                $('#event-header').css({'min-height': (935 + 550) + 'px'});
                
                page++;
            } else {
                $('.load-more-event').hide();
            }
        });
    });
});
