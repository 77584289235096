/**
 * Decide whether the header should be sticky or not.
 */
handleStickyHeader = function($) {
    var headerheight = $('header').outerHeight();
    var navheight = $('header #mega-menu').outerHeight();

    // Always sticky on mobile, sticky from 44px onwards on desktop.
    //if($(window).width() >= 768 && $(window).scrollTop() >= (headerheight - navheight)) {
    if( $(window).scrollTop() >= 50) {
        //$('header').css('padding-bottom', navheight + 'px');
        $('header').addClass('sticky');
    } else {
        //$('header').css('padding-bottom', '0px');
        $('header').removeClass('sticky');
    }
}


module.exports = function($, Modernizr) {
    $(document).ready(function() {
        // Dropdown mobile navigation.
        $('.burger').click(function(e) {
            e.preventDefault();

            $('.menu.main-menu').toggleClass('toggled');
        });

        // Clicking a li element on mobile shows its dropdown.
        $('.menu-item-has-children').click(function(e) {
            e.stopPropagation();

            if($(window).width() < 992) {
                $(this).toggleClass('toggled');
            }
        });

        // Clicking a li>a element on mobile opens the link.
        $('.menu-item-has-children a').click(function(e) {
            e.stopPropagation();
        });

        handleStickyHeader($);


        var mlMenu = new mlPushMenu( document.getElementById( 'mp-menu' ), document.getElementById( 'mobile-nav-btn' ), {
            type : 'cover'
        } );

        // mobile nav close btn click
        $(".close-nav").on("click", function() {
            mlMenu._resetMenu();
        })
    })

  /*   $("#mega-menu .nav-link").on("click", function(e) {
        $("#mega-menu").find('.dropdown-menu').addClass('hidden');
        $("#mega-menu").find('.dropdown-menu.active').removeClass('active');
        $(this).parent().find('.dropdown-menu').removeClass('hidden');
        $(this).parent().find('.dropdown-menu').addClass('active');
        
    }) */

    document.addEventListener('scroll', function() {
        handleStickyHeader($);
    }, Modernizr.passiveeventlisteners ? {passive: true} : false);
}
